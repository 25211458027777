import React, { useEffect } from "react"
import { graphql } from "gatsby"
import { TimelineLite, Power2 } from "gsap"
import styled from "styled-components"

import Layout from "../layouts/default"

const Info = ({ data }) => {
  const { seo, profileImage, description, contacts } = data.info

  useEffect(() => {
    let tl = new TimelineLite()
    let tlContacts = new TimelineLite()
    let tlInfoImageCover = new TimelineLite()
    let tlInfoImage = new TimelineLite()

    tl.from(".info__about", 1.6, {
      y: -10,
      opacity: 0,
      delay: 1.5,
      ease: Power2.easeOut,
      stagger: {
        amount: 0.4,
      },
    })
    tlContacts.from(".textblock-title, .textblock--contact", 1.6, {
      y: -10,
      opacity: 0,
      delay: 1.6,
      ease: Power2.easeOut,
      stagger: {
        amount: 0.4,
      },
    })
    tlInfoImageCover.to(".info__image-cover", 1.6, {
      delay: 1.6,
      css: {
        left: "100%",
      },
    })
    tlInfoImage.to(".info__image img", 1.6, {
      delay: 1.7,
      css: {
        transform: "scale(1)",
      },
    })
  }, [])

  return (
    <Layout>
      <StyledInfo className="info-page">
        <div className="wrapper">
          <div className="info-page__item info__text">
            <div className="info__about">
              <p
                dangerouslySetInnerHTML={{ __html: description }}
                className="textblock--text"
              />
            </div>
            {contacts.length !== 0 && (
              <div className="info__contacts">
                <h1 className="textblock-title textblock-title--contact">
                  Contatti:
                </h1>
                <ul>
                  {contacts.map((e, i) => (
                    <li key={i} className="textblock--contact">
                      <a href={e.link} target="_blank" title={e.link}>
                        {e.title}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
          <div className="info-page__item info__image">
            <img src={profileImage.url} alt="Carola Allemandi" />

            <div className="info__image-cover"></div>
          </div>
        </div>
      </StyledInfo>
    </Layout>
  )
}

export const InfoQuery = graphql`
  query InfoPageQuery($slug: String!) {
    info: datoCmsInfo(slug: { eq: $slug }) {
      pageTitle
      profileImage {
        url
      }
      description
      contacts {
        title
        link
      }
    }
  }
`

const StyledInfo = styled.section`
  /* min-height: 70vh; */
  height: calc(100vh - (120px));
  display: flex;
  justify-content: center;
  align-items: center;

  .wrapper {
    display: flex;
    flex-flow: column nowrap;

    @media (min-width: 1024px) {
      width: 90%;
      flex-flow: row nowrap;
      justify-content: space-between;
      align-items: flex-start;
      margin: 0 auto;
    }

    @media (min-width: 1200px) {
      width: 75%;
    }
  }

  .info__text {
    width: 100%;

    @media (min-width: 1024px) {
      width: 70%;
      display: flex;
      flex-flow: row nowrap;
      margin-top: 0;
    }

    h1 {
      font-size: 1.2rem;

      @media (min-width: 1024px) {
        font-size: 0.9rem;
        margin-bottom: 0;
      }
    }

    p {
      font-size: 1rem;
      line-height: 1.8;
      color: #4f4f4f;

      @media (min-width: 1024px) {
        font-size: 0.9rem;
      }

      &:not(:last-of-type) {
        margin-bottom: 0.8rem;
      }
    }

    li {
      font-size: 0.9rem;
      color: #4f4f4f;
      list-style-type: none;
      cursor: pointer;

      &:not(:last-of-type) {
        margin-bottom: 1rem;
      }
    }
  }

  .info__about,
  .info__contacts {
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    align-items: baseline;

    @media (min-width: 1024px) {
      flex-flow: column nowrap;
      width: 50%;
    }
  }

  .info__about {
    margin-bottom: 3rem;

    @media (min-width: 1024px) {
      margin-right: 5rem;
      margin-bottom: 0;
    }
  }

  .textblock-title {
    flex-basis: 20%;
    flex-shrink: 0;
    margin-right: 1rem;
  }

  .info__image {
    display: none;

    @media (min-width: 1024px) {
      display: block;
      width: 280px;
      height: 280px;
      position: relative;
      overflow: hidden;
    }

    img {
      width: 100%;
      transform: scale(1.2);
    }
  }

  .info__image-cover {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: none;

    background-color: #fff;
  }
`

export default Info
